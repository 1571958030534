import React from "react";
import './App.css';
import "./Material-Theme.css"

import Navbar from "./components/navbar/Navbar";

function App() {
  return (
      <Navbar/>
  //  <Footer/> will be added in once I build it.
  );
}

export default App;