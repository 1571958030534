import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Hero from "./pages/index/sections/Hero";
import Services from "./pages/index/sections/Services";
import About from "./pages/index/sections/About";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
      <Hero/>
      <Services/>
      <About/>
  </React.StrictMode>
);
