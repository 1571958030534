import "./Card.css"
import "../button/Button"

export default function Card(props) {
    let classes = `card ${props.type}`;

    if (props.disabled) {
        classes += " disabled";
    }

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}