import "./Services.css"
import Card from "../../../components/card/Card";
import Button from "../../../components/button/Button";

export default function Services() {
    return(
        <section id={"services"}>
            <Card type={"outlined"}>
                <h2 className={"title"}>Care Package</h2>
                <div className={"description"}>
                    <p>Our maintenance package is worry free. We handle the little things before they become big things. This package includes:</p>
                    <ul>
                        <li>Content updates and changes</li>
                        <li>Software updates</li>
                        <li>Security audits</li>
                        <li>Backup services</li>
                    </ul>
                </div>
                <div className={"cta-container"}>
                    <div>
                        <p className={"price"}>$150 / month</p>
                        <p className={"fine-print"}>Min. 6 months required.</p>
                    </div>
                    <Button type={"filled-tonal"} icon={"arrow_forward"} iconPosition={"end"}>View package</Button>
                </div>
            </Card>
            <Card type={"outlined"}>
                <h2 className={"title"}>Create Package</h2>
                <div className={"description"}>
                    <p>If you are looking for a new website, or an overhaul of your existing website, then this is the package for you. This package includes:</p>
                    <ul>
                        <li>New website / Rebuild of an existing website</li>
                        <li>Performance monitoring & optimization</li>
                        <li>Website analytics & reporting</li>
                        <li>Responsive design</li>
                        <li className={"bold"}>Everything in the Care Package</li>
                    </ul>
                </div>
                <div className={"cta-container"}>
                    <div>
                        <p className={"price"}>$275 / month</p>
                        <p className={"fine-print"}>Min. 1 year required.</p>
                    </div>
                    <Button type={"filled-tonal"} icon={"arrow_forward"} iconPosition={"end"}>View package</Button>
                </div>
            </Card>
            <Card type={"outlined"}>
                <h2 className={"title"}>Complete Package</h2>
                <div className={"description"}>
                    <p>For a blog, e-commerce stores, or other large websites.  This package includes:</p>
                    <ul>
                        <li>Product & category management</li>
                        <li>Payment gateway management</li>
                        <li>Search Engine Optimization (SEO)</li>
                        <li>A/B testing</li>
                        <li>Checkout optimization</li>
                        <li className={"bold"}>Everything in the Create Package</li>
                    </ul>
                </div>
                <div className={"cta-container"}>
                    <div>
                        <p className={"price"}>$400 / month</p>
                        <p className={"fine-print"}>Min. 1 year required.</p>
                    </div>
                    <Button type={"filled-tonal"} icon={"arrow_forward"} iconPosition={"end"}>View package</Button>
                </div>
            </Card>
        </section>
    );
}