import "./Button.css"
export default function Button(props) {
    const icon = <span className={`material-symbols-rounded`}>{props.icon}</span>;
    let classes = `button ${props.type}`;

    if (props.icon) {
        classes += ` ${props.iconPosition}`;
    }

    if (props.disabled) {
        classes += " disabled";
    }

    return (
        <a
            className={classes}
            href={props.link}
        >
            {(props.iconPosition === "start") ? icon : null}
            {props.children}
            {(props.iconPosition === "end") ? icon : null}
        </a>
    )
}