import "./Hero.css"
import Button from "../../../components/button/Button";

export default function Hero() {
    return(
        <section id={"hero"}>
            <div id={"left-side"}>
                <h1>Your website<br />always evolving.</h1>
                <p>Ensuring your website keeps up with<br/>the changing needs of your business.</p>
                <Button
                    type={"filled-tonal"}
                    link={"/work"}
                    icon={"work"}
                    iconPosition={"start"}
                >
                    View our work
                </Button>
            </div>
            <div id={"right-side"}>
                <img
                    src={"./assets/images/responsive-website.svg"}
                    alt={"Cartoon style drawing of a website wireframe turning into a website shown on a laptop. Drawn by Stories on FreePik"}
                />
            </div>
        </section>
    );
}