import "./Navbar.css"
import Button from "../button/Button";

export default function Navbar() {
    return (
        <nav>
            <a href={"/"}>
                <img className={"logo"} src={"../assets/images/Kretz_Coding_Logo-Blue_Background.svg"} alt={"Kretz Coding logo"}/>
            </a>
            <ul className={"nav-list"}>
                <li className={"nav-item"}><a className={"nav-link"} href="/about">About</a></li>
                <li className={"nav-item"}><a className={"nav-link"} href="/services">Services</a></li>
                <li className={"nav-item"}><a className={"nav-link"} href="/work">Our Work</a></li>
                <li className={"nav-item"}><a className={"nav-link"} href="/pricing">Pricing</a></li>
            </ul>
            <Button type={"filled"} link={"./contact"}>Contact us</Button>
        </nav>
    );
}